<script>
export default {
  name: "Constants",
  // apiUrl: "http://services.akead.link/akead-pro/V0006/", // root request
  apiUrl: "https://dcpservicestest.akead.link/V0000/", // root request
  paymentUrl: "http://localhost/param/V0000/", // local request
  // apiUrl: "https://servicestest.akead.link/akead-pro/V0006_sale3d/", // test request
  apiMethod: {
    // auth ----------------------------------------------
    login: "Membership/login", // Post
    logout: "membership/logout", // Del
    register: "membership/register", // Post
    renewPassword: "membership/renewPasswordMail?email=",
    // membership ----------------------------------------
    userProfile: "Membership/userProfile", // Get
    userWholesaler: "wholesaler/userWholesalers", // Get- Put
    changePassword: "membership/changePassword", // Put
    // wholesaler ----------------------------------------
    connectWholesaler: "wholesaler/connectToWholesaler",
    invitationCode: "wholesaler/acceptClientInvitation?invitationCode=",
    leaveWholesaler: "wholesaler/disconnectFromWholesaler",
    // trade ---------------------------------------------
    orders: "trade/orders",
    order: "trade/orderItems?orderId",
    createOrder: "trade/createOrder", // post
    deliveries: "trade/deliveriesForCustomer",
    delivery: "trade/deliveryForCustomer",
    invoices: "trade/invoicesForCustomer",
    invoice: "trade/invoiceItems?invoiceId=",
    productDetail: "trade/product?id=",
    campaignColors: "trade/campaigns?test=1",
    sale3d: "trade/sale3d",
    accountActivities: "trade/clientAccountActivities?start_date=2020-01-01&finish_date=2099-12-31",
    initialize: "http://localhost/param/V0000/SetPayment/initialize",
    // highlightedProducts: 'trade/highlightedProducts',
    highlightedProducts:
      "trade/highlightedProducts?test=1&includeRelatedObjects=1",
    recentlyPurchasedProducts:
      "trade/recentlyPurchasedProducts?test=1&includeRelatedObjects=1",
    favoriteProducts: "trade/favoriteProducts",
    favoriteProduct: "trade/favoriteProduct",
      productGroups: "trade/productGroups?parentGroupCodePath=",
    products: "trade/products?groupCodePath=",
    searchPanel: "trade/searchProducts?searchText=",
    // basketItem ----------------------------------------
    generateBasketItem: "trade/generateBasketItem?",
  },
  filterMethod: {
    and: "AND",
    or: "OR",
  },
};
</script>
