<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <PageTemplate>
    <template slot="title">{{ this.$t("account_activities") }} </template>
    <template slot="content">
      <div style="margin: 8px">
        <v-data-table
            :headers="headers"
            :items="newItem"
            no-data-text="no item in Account Activities"
            :loading-text="$t('loading').concat('...')"
            :footer-props="{'items-per-page-options':[15, 30, 50, 100,200,-1]}"
            :options="options"
            class="elevation-10"
        >
          <template v-slot:item.dat_pay="{ item }">
            <span>{{ formatDate(item.dat_pay) }}</span>
          </template>
          <template v-slot:item.debit="{ item }">
            <span v-if="item.debit === '0' ? '': item.debit">{{ item.debit }} {{item.currency_reg}}</span>
          </template>
          <template v-slot:item.credit="{ item }">
            <span v-if="item.credit === '0' ? '': item.credit">{{ item.credit }} {{item.currency_reg}} </span>
          </template>1
          <template v-slot:item.remainder="{ item }">
            <span>{{ item.remainder }} {{item.currency_reg}}</span>
          </template>
          $store.state.userProfile.currentWholesaler.settings.currencyCode
          <template v-slot:item.debit_main="{ item }">
            <span v-if="item.debit_main === '0' ? '': item.debit_main">{{ item.debit_main }} {{$store.state.userProfile.currentWholesaler.settings.currencyCode}}</span>
          </template>
          <template v-slot:item.credit_main="{ item }">
            <span v-if="item.credit_main === '0' ? '': item.credit_main">{{ item.credit_main }} {{$store.state.userProfile.currentWholesaler.settings.currencyCode}}</span>
          </template>
          <template v-slot:item.remainder_main="{ item }">
            <span>{{ item.remainder_main }} {{$store.state.userProfile.currentWholesaler.settings.currencyCode}}</span>
          </template>


        </v-data-table>
      </div>
    </template>
  </PageTemplate>
</template>

<script>
import { mapActions } from "vuex";
import Constants from "@/Constants";
import PageTemplate from "../../templates/PageTemplate";
// import moment from "moment-timezone";
export default {
  name: "AccountActivities",
  components: { PageTemplate },
  data() {
    return {      
      options: {
      itemsPerPage: 100
      },
      data: [],
      items: [],
      newItem: [],
      headers: [],
      header_1: [
        {
          text: this.$t("date"),
          value: "dat_pay",
          align: "center",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("invoice_no"),
          value: "no_inv",
          align: "center",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("info"),
          value: "info",
          align: "center",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("debit"),
          value: "debit",
          align: "right",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("credit"),
          value: "credit",
          align: "right",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("remainder"),
          value: "remainder",
          align: "right",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("debit"),
          value: "debit_main",
          align: "right",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("credit"),
          value: "credit_main",
          align: "right",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("remainder"),
          value: "remainder_main",
          align: "right",
          sortable: false,
          width: "10%"
        },
      ],
      header_2: [
        {
          text: this.$t("date"),
          value: "dat_pay",
          align: "center",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("invoice_no"),
          value: "no_inv",
          align: "center",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("info"),
          value: "info",
          align: "center",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("debit"),
          value: "debit",
          align: "right",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("credit"),
          value: "credit",
          align: "right",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t("remainder"),
          value: "remainder",
          align: "right",
          sortable: false,
          width: "10%"
        },
      ],
    };
  },
  watch: {

  },
  created() {
    this.showProgressBar();
    this.formatDate("20200620")
    this.getAccountActivities()
  },
  methods: {
    formatDate(value) {
      if(value ===""){
        return ""
      }
      else {
      var year = value.substring(0, 4);
      var month = value.substring(4, 6);
      var day = value.substring(6, 8);
      var newValue = day + '/' + month + '/' + year;
      return newValue
      }
    },
    getAccountActivities(){
      this.$http.get(Constants.apiMethod.accountActivities).then((response)=>{
        console.log(response.data)
        this.items = response.data.customer_account_activities
        this.data = response.data.total_amounts
        var total = {
              "dat_pay": "",
              "no_inv": "",
              "no_doc": "",
              "receipt_no": "",
              "account_name": "",
              "info": this.$t("Toplam"),
              "debit": response.data.total_amounts[0].total_debit + " " + response.data.customer_account_activities[0].currency_reg,
              "credit": response.data.total_amounts[0].total_credit + " " + response.data.customer_account_activities[0].currency_reg,
              "remainder": response.data.total_amounts[0].remainder + " " + response.data.customer_account_activities[0].currency_reg,
              "id_doc": "",
              "typ_doc": "",
              "id_pay": "",
              "currency_reg":  "",
              "typ_sal_pur": "1",
              "debit_main": response.data.total_amounts[0].total_debit_main,
              "credit_main": response.data.total_amounts[0].total_credit_main,
              "remainder_main": response.data.total_amounts[0].remainder_main
            }
            this.items.push(total)
        // var credit = {
        //   "dat_pay": "",
        //   "no_inv": "",
        //   "no_doc": "",
        //   "receipt_no": "",
        //   "account_name": "",
        //   "info": "Bakiye",
        //   "debit": "",
        //   "credit": response.data.total_amounts.total_credit,
        //   "remainder": "",
        //   "id_doc": "",
        //   "typ_doc": "",
        //   "id_pay": "",
        //   "currency_reg":  "",
        //   "typ_sal_pur": "1",
        //   "debit_main": "",
        //   "credit_main": response.data.total_amounts.total_credit_main,
        //   "remainder_main": ""
        // }
        // this.items.push(credit)
            this.newItem = this.items
       if(this.$store.state.userProfile.currentWholesaler.settings.currencyCode !== this.data.currency_reg){
         this.headers = this.header_2
       } else { this.headers = this.header_1}
       // console.log(total)
        this.hideProgressBar()
      },(error)=>{
        console.log(error)
      })
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>

<style scoped>
::v-deep .v-data-table td+td{
  border-left:2px solid #dddddd;
}
::v-deep .v-data-table th+th{
  border-left:2px solid #dddddd;
}
::v-deep .v-data-table-header{
  text-align-last: center
}
</style>
