<template>
  <page-template>
    <template slot="title"> {{ this.$t("your_wholesalers") }}</template>
    <template slot="content">
      <v-layout justify-center align-center style="margin: 8px">
        <v-list width="600" class="elevation-6" v-show="!noData">
          <v-list-item
            :key="userWholesaler.name"
            @click="onClickWholesaler(userWholesaler)"
            v-for="userWholesaler in wholesalers"
          >
            <v-list-item-avatar />
            <v-list-item-content>
              <v-list-item-title>
                {{ userWholesaler.wholesaler.name }}
                {{ " /" }}
                {{ userWholesaler.client.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <not-found v-show="noData" data="no_user_wholesaler_found"/>
      </v-layout>

      <v-dialog v-model="connectionDialog" max-width="450">
        <v-card>
          <v-card-title class="view_headline"
            >{{ this.$t("your_wholesaler") }}:
            {{ wholesaler.wholesaler.name }}
          </v-card-title>
          <v-divider />
          <v-card-actions>
            <v-layout justify-end>
              <v-btn
                color="accent"
                text
                class="body-2"
                @click="connectionDialog = false"
                >{{ this.$t("cancel") }}</v-btn
              >

              <v-btn
                color="accent"
                class="body-2"
                @click="connectWholesaler()"
                >{{ this.$t("connect") }}</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="invitationDialog" width="400px">
        <v-card>
          <v-card-title class="view_headline">{{
            this.$t("add_wholesaler")
          }}</v-card-title>
          <v-divider />
          <v-text-field
            style="margin: 15px"
            :label="$t('invitation_code')"
            v-model="invitationCode"
          />
          <v-card-actions style="padding-top: 0">
            <v-layout justify-end>
              <v-btn
                text
                color="accent"
                class="body-2"
                @click="invitationDialog = false"
                >{{ this.$t("cancel") }}</v-btn
              >

              <v-btn
                color="accent"
                class="body-2"
                @click="clientInvitation()"
                >{{ this.$t("ok") }}</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn
        fab
        color="accent"
        fixed
        bottom
        right
        @click="addClientInvitation()"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </template>
  </page-template>
</template>

<script>
import { mapActions } from "vuex";
import Constants from "@/Constants";
import PageTemplate from "../../templates/PageTemplate";
import NotFound from "../scada/NotFound";
export default {
  name: "Wholesaler",
  components: { PageTemplate,NotFound },
  created() {
    this.$store.dispatch("hideProgressBar");
    console.log("wholesalerPage_Created");
    // this.$store.dispatch("loadUserProfile", () => {
    //   console.log(this.$store.state.userProfile);
    //   console.log(this.$store.state.currentWholesaler);
    //   this.$store.state.currentWholesaler = this.$store.state.userProfile.currentWholesaler.name;
    // });
    this.getWholesaler();
  },
  data() {
    return {
      noData: false,
      connectionDialog: false,
      invitationDialog: false,
      invitationCode: "",
      wholesaler: {
        wholesaler: {
          name: null,
        },
      },
      a: [],
      wholesalers: {},
      item: { user_wholesaler_id: "" },
    };
  },

  methods: {
    getWholesaler() {
      this.$http.get(Constants.apiMethod.userWholesaler).then(
        (response) => {
          this.wholesalers = response.data;
          if(this.wholesalers.length === 0){
            this.noData = true
          }
          else if(response.data.length=== 1){
            this.item.user_wholesaler_id = response.data[0].id
            this.connectWholesaler();
          }
          this.$store.dispatch("hideProgressBar")
          console.log(response.data);
        },
        () => {
          window.alert("no wholesalers found");
        }
      );
    },
    onClickWholesaler(wholesaler) {
      this.wholesaler = wholesaler;
      this.item.user_wholesaler_id = wholesaler.id;
      this.connectionDialog = true;
      console.log(wholesaler);
    },
    connectWholesaler() {
      this.$store.dispatch("showProgressBar");
      this.$http.post(Constants.apiMethod.connectWholesaler, this.item).then((response) => {
        console.log(response.data);
        if(response.data.error_code ===1504){
          this.$store.state.alertDialog.message = this.$t("process_fail_message") + response.data.error_code;
          this.$store.state.alertDialog.visibility = true;
          this.$refs.form.reset();
        }
        else {
          this.$store.dispatch("campaignColors")
          this.$store.dispatch("loadUserProfile", () => {
            this.$store.state.badgeCounter = 0;
            this.$store.state.menuRestriction = true
            this.$router.push({name: "HighlightedProducts"});

          });
        }
      },(err)=>{
        this.$store.state.alertDialog.message = this.$t("process_fail_message") + err.data.message;
        this.$store.state.alertDialog.visibility = true;
        console.log(err)
      });
      this.connectionDialog = false;
    },
    addClientInvitation() {
      this.invitationDialog = true;
    },
    clientInvitation() {
      this.$http
        .put(Constants.apiMethod.invitationCode.concat(this.invitationCode))
        .then((response) => {
          console.log(response.data);
          this.getWholesaler();
          this.$router.push({ name: "Wholesaler" });
        });
    },
    ...mapActions(["showProgressBar", "hideProgressBar", "loadUserProfile"]),
  },
};
</script>

<style scoped>
.v-app-bar {
}
</style>
