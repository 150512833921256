<template>
  <PageTemplate>
    <template slot="title">{{ this.$t("documents") }}</template>
    <template slot="content">
      <v-tabs
        centered
        color="white"
        dark
        class="elevation-10 ma-1"
        background-color="accent"
      >
        <v-tab class="body-2">{{ this.$t("orders") }}</v-tab>
        <v-tab-item>
          <v-data-table
            :headers="headers"
            :items="ordersData"
            no-data-text="Loading"
            class="elevation-10"
            @click:row="itemAction"
          >
            <template v-slot:item.createDate="{ item }">
              {{ formatDate(item.createDate) }}
            </template>
            <template v-slot:item.totalWithoutTax="{ item }">
              {{ fixed(item.totalWithoutTax) }} {{ item.currency }}
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab class="body-2">{{ this.$t("invoice") }}</v-tab>
        <v-tab-item>
          <v-data-table
            :headers="invoiceHeader"
            :items="invoicesData"
            no-data-text="Loading"
            class="elevation-10"
            @click:row="invoiceAction"
          >
            <template v-slot:item.expiryDate="{ item }">
              {{ formatDate(item.expiryDate) }}
            </template>
            <template v-slot:item.totalAmount="{ item }">
              {{ fixed(item.totalAmount) }} {{ item.currencyCode }}
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab class="body-2">{{ this.$t("delivery") }}</v-tab>
        <v-tab-item>
          <v-data-table
            :headers="deliveriesHeader"
            :items="deliveriesData"
            no-data-text="Loading"
            class="elevation-10"
            @click:row="invoiceAction"
          >
            <template v-slot:item.expiryDate="{ item }">
              {{ formatDate(item.date) }}
            </template>
            <template v-slot:item.totalAmount="{ item }">
              {{ fixed(item.totalAmount) }} {{ item.currencyCode }}
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </template>
  </PageTemplate>
</template>

<script>
import { mapActions } from "vuex";
import Constants from "@/Constants";
import PageTemplate from "../../templates/PageTemplate";
import moment from "moment-timezone";
export default {
  name: "Orders",
  components: { PageTemplate },
  data() {
    return {
      titles: {
        toolbarTitle: "",
      },
      ordersData: [],
      invoicesData: [],
      deliveriesData: [],
      orderData: {},
      pagination: {},
      deliveriesHeader: [
        {
          text: this.$t("date"),
          value: "date",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("number"),
          value: "number",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("total"),
          value: "totalAmount",
          align: "center",
          sortable: false,
        },
      ],
      invoiceHeader: [
        {
          text: this.$t("date"),
          value: "expiryDate",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("number"),
          value: "number",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("total"),
          value: "totalAmount",
          align: "center",
          sortable: false,
        },
      ],
      headers: [
        {
          text: this.$t("date"),
          value: "createDate",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("status"),
          value: "status",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("total"),
          value: "totalWithoutTax",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("showProgressBar");
    this.getDocuments();
    console.log("Created Orders");
  },
  methods: {
    fixed(value) {
      return parseFloat(value).toFixed(2);
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    itemAction(item) {
      this.showProgressBar();
      console.log(item);
      this.$router.push({ name: "Order", params: { order: item } });
    },
    invoiceAction(item) {
      this.showProgressBar();
      console.log(item);
      this.$router.push({ name: "Invoice", params: { invoice: item } });
    },
    deliveryAction(item) {
      this.showProgressBar();
      console.log(item);
      this.$router.push({ name: "Invoice", params: { invoice: item } });
    },
    getDocuments() {
      this.$http.get(Constants.apiMethod.orders).then((response) => {
        this.ordersData = response.data;
        for (var i = 0; i < this.ordersData.length; i++) {
          if (this.ordersData[i].status === 1) {
            this.ordersData[i].status = this.$t("approved");
          } else if (this.ordersData[i].status === 2) {
            this.ordersData[i].status = this.$t("canceled");
          } else if (this.ordersData[i].status === 3) {
            this.ordersData[i].status = this.$t("partial_delivered");
          } else if (this.ordersData[i].status === 4) {
            this.ordersData[i].status = this.$t("delivered");
          } else if (this.ordersData[i].status === 5) {
            this.ordersData[i].status = this.$t("invoiced");
          } else if (this.ordersData[i].status === 6) {
            this.ordersData[i].status = this.$t("blocked");
          } else if (this.ordersData[i].status === 0) {
            this.ordersData[i].status = this.$t("unapproved");
          }
          this.ordersData[i].totalWithoutTax =
            this.ordersData[i].totalWithoutTax +
            " " +
            this.ordersData[i].currency;
        }
        console.log(this.ordersData);
      });
      this.$http.get(Constants.apiMethod.invoices).then((response) => {
        this.invoicesData = response.data;
        console.log(response.data);
      });
      this.$http.get(Constants.apiMethod.deliveries).then((response) => {
        this.deliveriesData = response.data;
        console.log(response.data);
      });
      this.$store.dispatch("hideProgressBar");
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>

<style scoped>
.v-toolbar {
}
</style>
